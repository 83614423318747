import React, {useContext, useState} from 'react';
import myContext from '../mycontext';
import {Avatar, Button, IconButton, Popover, Stack } from '@mui/material';
import firebase from 'firebase/compat/app';
import { Navigate } from 'react-router-dom';
import {LogOut} from '../myFirebase'

function MyAvatar(){
    const [anchorEl, setAnchorEl] = useState(null);
    const {user,setUser} = useContext(myContext);

    function log_out() {
        setUser(null);
        LogOut();
        //should trigger context in my_Firebase
        //console.log('logging out')
        //console.log(user);
      }
      

    function MyPopover(props){
        return (
    <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <Stack>
                <Button onClick={log_out} >Sign Out</Button>
                <Button>Coming Soon</Button>
                <Button>Coming Soon</Button>
            </Stack> 
          </Popover>
        )
        }

        
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
      };

    if (user != null){
    return (
        <div> 
            <IconButton onClick={handleClick} id='avator-button'>
                <Avatar src={user.photoURL}
                >
                </Avatar>
            </IconButton>
            <MyPopover id='avator-button' open={open} anchorEl={anchorEl} onClose={handleClose}/>
            
        </div>
    )}
    else {

        return <Navigate to='/SignIn'></Navigate>
    }
}

export default MyAvatar;