import React from 'react';
// https://stripe.com/docs/api/products/list#list_products
// Stripe return object
// {"id": "prod_MjmdBqjrwQxa98",
//       "object": "product",
//       "active": true,
//       "created": 1667542048,
//       "default_price": "price_1M0J3tB8L3kdvj5uHzZqRqkw",
//       "description": "Find out what's being posted where",
//       "images": [
//         "https://files.stripe.com/links/MDB8YWNjdF8xS1JWTUlCOEwza2R2ajV1fGZsX3Rlc3RfT0l5TWNONW1zNzJHNnVzQndyM3lNdFl200FZmwoekF"
//       ],
//       "livemode": false,
//       "metadata": {},
//       "name": "Facebook Marketplace Scraper",
//       "package_dimensions": null,
//       "shippable": null,
//       "statement_descriptor": null,
//       "tax_code": "txcd_10000000",
//       "unit_label": null,
//       "updated": 1667542049,
//       "url": null}

export default function StripeObject(stripe_obj){
    const data = stripe_obj.StripeObject
    console.log(data)
    return (
        <div className='py-6'>
            <div className='flex max-w-md bg-white shadow-lg rounded-lg overflow-hidden'>
                <img className= 'w-1/3 bg-cover' src={data.images[0]}></img>

                <div class="w-2/3 p-4">
                    <div className='text-gray-900 font-bold text-2xl'> {data.name}</div>
                
                    <p>{data.description}</p>
                </div>
            </div>
            
        </div>
        )
}