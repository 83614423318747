import React,{useState, useEffect} from 'react';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport, gridClasses, GridRowsProp, GridColDef} from '@mui/x-data-grid';
import {  Box, CircularProgress,Stack,
    Stepper, Step, StepLabel, 
    TextField,InputAdornment, Button, 
    TableHead, Table, TableBody,TableCell,TableRow,LinearProgress } from '@mui/material';
import {ScrapeGeneral} from '../data/api';
import SearchIcon from '@mui/icons-material/Search';

function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const handle_table = (cols_, vals_ ) => {
    console.log('COLS: ',cols_)
    console.log('VALS: ',vals_)
    // { id: 1, col1: 'Hello', col2: 'World' },
    const rows = vals_.map((i)=>{
        i['id'] = i['index']
        delete i['index']
        return i
    })
    
    // [
    //     { id: 1, col1: 'Hello', col2: 'World' },
    //     { id: 2, col1: 'DataGridPro', col2: 'is Awesome' },
    //     { id: 3, col1: 'MUI', col2: 'is Amazing' },
    //   ];
    // { field: 'col1', headerName: 'Column 1', width: 150 },
      const columns = cols_.map((row)=>{
        return { field: row['name'], headerName: row['name'], width: 150 }
      })

    // const rows = Object.values(vals_)
    console.log('ROWS',rows)
    return(
            <DataGrid
            className='h-full'
            columns={columns}
            rows={rows}
            pageSize={20}
            autoHeight
            rowsPerPageOptions={[10]}
            checkboxSelection
            components={{
                Toolbar: CustomToolbar,
            }}/>
    )
}

const make_table = (data)=>{
   
    console.log('b4',data.resp)
    console.log(Object.keys(data.resp))
    
    return Object.keys(data.resp).map((d)=> {
        console.log('make_table: ', data.resp[d].schema.fields, data.resp[d].data)
        return handle_table(data.resp[d].schema.fields, data.resp[d].data)
    })
}

const execute_scrape = (url) => {

    return ScrapeGeneral({url:url})

}

export default function GeneralScrape(){
    const [search,setSearch] = useState('');
    const [response, setResponse] = useState(null);
    const [execute, setExecute]  = useState(0);
    const [loading, setLoading]  = useState(false);

    useEffect(()=>{
        console.log(execute)
        if(execute != 0){
            setLoading(true)
            execute_scrape(search).then((r)=>{
                setResponse(r.data)
            }).catch((e)=>{
               console.log(e)
               alert('Error scraping the site!')
                setLoading(false)
            })
            setLoading(false)
        }
    },[execute])



    return <div>
                <Box sx={{height:100}}></Box>
                
            <Stack>
                <Box sx={{height:100}}></Box>
            

            <TextField
                label="Url"
                defaultValue='Url must contain "http" or "https" to begin'
                onChange={(e)=>{setSearch(e.target.value); }}
                value={search}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <SearchIcon />
                        </InputAdornment>
                    ),
                    }}
                    variant="standard"
                />
            <Box sx={{height:20}}></Box>
                <Button 
                    variant='outlined'
                    onClick={()=>{setExecute(execute+1)}}
                    disabled={ 
                            search.toLowerCase().includes('http')==true && !loading ? false : true}s
                    >Execute</Button>
            <Box sx={{height:100}}></Box>
                        
            {response!=null? make_table(response):<></>}
            </Stack>
    </div>
}