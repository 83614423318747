import * as React from 'react';
import BuildIcon from '@mui/icons-material/Build';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import StorageIcon from '@mui/icons-material/Storage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '../components/Button';
import Typography from '../components/Typography';

const styles = {
  largeIcon: {
    width: 60,
    height: 60,
  },
};

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const number = {
  fontSize:42,
  fontFamily: 'default',
  color: 'secondary.main',
  fontWeight: 'light',
};

const image = {
  height: 55,
  my: 4,
};

function ProductHowItWorks() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', bgcolor: 'secondary.light', overflow: 'hidden' }}
    >
      <Container
        sx={{
          mt: 10,
          mb: 15,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          component="img"
          src="/static/themes/onepirate/productCurvyLines.png"
          alt="curvy lines"
          sx={{
            pointerEvents: 'none',
            position: 'absolute',
            top: -180,
            opacity: 0.7,
          }}
        />
        <Typography variant="h4" marked="center" component="h2" sx={{ mb: 14 }}>
          The Process..
        </Typography>
        <div>
          <Grid container spacing={5}>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>1. {<BuildIcon color='primary' sx={styles.largeIcon}/>}</Box> 
                <Typography variant="h5" align="center">
                 If you have the data scripts, we integrate them. If not, we make them.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item}>
                <Box sx={number}>2. {<AutoGraphIcon color='primary' sx={styles.largeIcon}/>}</Box>
               
                <Typography variant="h5" align="center">
                  We provide an intuitive interface to run the scripts.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={item} container='flex'>
                <Box sx={number}>3. {<StorageIcon color='primary' sx={styles.largeIcon}/>}</Box> 
  
                <Typography variant="h5" align="center">
                  The data is either returned to you in the browser, or stored in a data base for Trend-Over-Time tracking.   
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Button
          color="secondary"
          size="large"
          variant="contained"
          component="a"
          href="/SignUp"
          sx={{ mt: 8 }}
        >
          Get started
        </Button>
      </Container>
    </Box>
  );
}

export default ProductHowItWorks;
