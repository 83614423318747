import React from 'react'
import { AppBar,Toolbar,IconButton,Typography, Box
 } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';



export default function AppBarCustom(props){

    return(
    <Box sx={{ flexGrow: 1 }}>
    <AppBar position="static" >
        <Toolbar >
        <Box display='flex' flexGrow={1}>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            
            </IconButton>
            </Box>
            <Box display='flex' flexGrow={1}>
            <Typography variant="h6"  noWrap component="div" sx={{ flexGrow: 1 }}>Home</Typography>
            </Box>
            {props.avatar}
          </Toolbar>
    </AppBar>
    </Box>
    )
}