/* eslint-disable */
import React,{useState, useEffect} from 'react';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport, gridClasses, } from '@mui/x-data-grid';
import {  Box, Grid,CircularProgress,Stack,Switch, FormControlLabel,
    Stepper, Step, StepLabel, 
    TextField,InputAdornment, Button, 
    TableHead, Table, TableBody,TableCell,TableRow,LinearProgress } from '@mui/material';

import SavedDataDropDown from '../customComponents/saved_data_dropdown'
import {ScrapeMapsGFD} from '../data/api';
import SearchIcon from '@mui/icons-material/Search';



const handle_csv = (csv) => {
    console.log(csv)
    var list = csv.split('\r\n')
    if (list.length <=1){
        list = csv.split('\r')
    }
    if (list.length <=1){
        list = csv.split('\n')
    }
    const cols = list[0].split(',')
    const vals = list.slice(1)

    return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {cols.map((val, idx)=>{return <TableCell align="center">{val}</TableCell>})}
                </TableRow>
            </TableHead>
            <TableBody>
            {vals.map((row,idx)=>{// vall is a row of csv val1,vas2, val3
            //console.log(row)
             return (<TableRow>
                 {row.split(',').map((val, idx)=>{
                     return <TableCell align="center">{val}</TableCell>
                     })}
             </TableRow>)
            })}

            </TableBody>
      </Table>
    )
}

function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function GoogleScraper(){
    const [progress,setProgress] = useState(5);
    const [loading,setLoading] = useState(false);
    //Could use a big dict, but this feel easier ot com back to
    const [search,setSearch] = useState('Coffee Shops in {city}, {state}');
    const [csv,setCSV] = useState('');
    const [userSavedData,setUserSavedData] = useState();
    const [csvCols,setCsvCols] = useState([]);
    const [step,setStep] = useState(0);
    const [mapsData,setMapsData] = useState([]);
    const [mapsDataContainer,setMapsDataContainer] = useState([]);
    const [expectedReturnCount,setExpectedReturnCount] = useState([]);
    const [returnTable,setReturnTable] = useState(<></>);

    useEffect(()=>{
        if (mapsData!= null){
        const new_ = mapsDataContainer.concat(mapsData)
        setMapsDataContainer(new_)
        }
    },[mapsData])


    useEffect(()=>{
        if (mapsDataContainer!= null){
            //console.log(mapsDataContainer)
            setProgress((expectedReturnCount/mapsDataContainer.length) * 100)
            update_return_table()
        }
        if(expectedReturnCount/mapsDataContainer.length==1){
            setLoading(false);
        }
    },[mapsDataContainer])

    const steps = [
        'Start by entering your google maps search',
        'Upload cities, choose scraping options',
        'Check paramets, run scrape.'

    ]

    //is this more or less clear than just calling it?
    const update_return_table = () =>{
        //console.log('return table', createReturnTable(mapsDataContainer))
        setReturnTable(createReturnTable(mapsDataContainer))
    
    }

    const execute_search = () => {

        if (csv==null || search==null){
            alert('Please make sure a valid query and csv were given.')
            return ''
        }
        setLoading(true);

        const list = csv.split('\r\n')
        const cols = list[0].split(',')
        setCsvCols(cols)
        const vals = list.slice(1)
        
        const data = vals.map((val, idx)=>{
            const csv_val = cols.join(',') + '\r\n' + val
            return {query:search, csv:csv_val}
        })

        setExpectedReturnCount(vals.length)

        data.map((req,idx)=>{
            console.log('running: ', req.city)
            setTimeout(()=>{
                ScrapeMapsGFD(req).then((r)=>{
                    setMapsData(r.data)
                }
                ).catch(
                    (r)=>{
                        console.log(r)

                    }
                )
            },
            idx*1000)
    })
    }

    const createReturnTable = (data) =>{
        if (data.length==0){
            return <></>
        }
        const columns = [
            { field: 'id', headerName: 'ID', width: 70},
            { field: 'count', headerName: 'Count', width: 70} ,
            { field: 'query', headerName: 'Query', width: 200} 
        ]

        const rows = data.map((val, idx)=>{
            return {id:idx, count:val['count'], query:val['query'] }
        })
        
        return (
            <div style={{ height: '600px', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    components={{
                        Toolbar: CustomToolbar,
                      }}
                />
            </div>
        )
    
    }

    const  CityStep = (p) => {
        return (
            <SavedDataDropDown
                setData={setCSV}
                button={
                <Button
                    variant="contained"
                    component="label"
                    >
                    Upload File
                    <input
                        onChange={p.onChange}
                        type="file"
                        hidden
                    />
                </Button>}
                />
        )
    }
    
    const ExecuteStep = () => {
        return (
            <Box align="center" sx={{ height:40, width: '100%' }}>
                <Stack>
                    <Button 
                    onClick={execute_search}
                    >Execute</Button>
                </Stack>
            {loading?<><CircularProgress/> <LinearProgress variant="determinate" value={progress} /> </>
                    :<></>}
           
            </Box>
            )
    }

    const load_file = (e)=> {
        var reader = new FileReader()
        console.log(reader.readAsText(e.target.files[0]))
        reader.onload = () =>{
            
            setCSV(reader.result)
        }
    
    }


    const mapping = {
        //weird begin when creating ): was insid efunciton in component
        0:<TextField
            label="Input Google Search..."
            defaultValue='Coffee Shops in {city}, {state}'
            onChange={(e)=>{setSearch(e.target.value); }}
            value={search}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <SearchIcon />
                    </InputAdornment>
                ),
                }}
                variant="standard"
            />,
        1:<CityStep onChange={load_file}  />,
        2:<ExecuteStep/>,
    }

    
    
    const MyStepper =()=>{
        return(
            <Box display='flex' sx={{ width: '100%' }}>
            <Stepper activeStep={step} alternativeLabel>
                {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
        </Box>
        )
    }
    
    return (
       

            <div>
                <Box sx={{height:100}}></Box>
            <MyStepper/>
            <Stack>
                <Box sx={{height:100}}></Box>
                {mapping[step]}
                <Box sx={{height:100}}></Box>
            <Box 
                m={1}
                component="span"
                justifyContent= "space-between"
                sx={{
                  alignItems: "center",
            }}
            >
            {step>0?<Button 
                component="label"
                onClick={()=>{setStep(step-1)}}> Previous</Button>:<Button disabled>Previous</Button>}              
            
                {step >=2? <Button disabled>Next</Button>:<Button
                component="label" onClick={()=>{setStep(step+1)}}>Next</Button> }
            </Box>    
            {csv != null && step==1? <div>{handle_csv(csv)}</div>: <></>}
            {mapsDataContainer.length==0?<></>:<div>{returnTable}</div>}
         </Stack>
         </div>
        )
}


