import React,{useContext, useState, useEffect} from 'react';
import {  Box,  Container, Button, Typography,
        Table, TableBody,TableCell,
        TableRow, TableContainer, Paper,
        IconButton, Fab, Divider, } from '@mui/material';
    
import {getSavedData, addSavedData, deleteData,getSavedDoc} from '../data/data_settings'
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import myContext from '../mycontext';
import{  Dialog, DialogActions, DialogTitle, DialogContent,
    DialogContentText, Stack, TextField } from '@mui/material';

const UploadDialog = (props) => {

        return (
                <Dialog 
                    open={props.open} 
                    onClose={props.handleClose}>
                <DialogTitle>Add A File</DialogTitle>
                {props.children}
            </Dialog>
            )
        }

export default function DataSettings(){

    const [open, setOpen] = useState(false);
    const [tableContainer, setTableContainer] = useState();
    const [tableData, setTableData] = useState();
    const [fileTitle,setFileTitle] = useState();
    const [file,setFile] = useState();
    const {user, _} = useContext(myContext);

    // const temp_add =()=>{addSavedData(user,{title:'Test', data:1234}).then((r)=>{console.log('add data',r)})}
    // const temp_get =()=>{getSavedData(user).then((r)=>{console.log('get data',r)})}
    // const temp_del =()=>{deleteData(user, 6).then((r)=>{console.log('del data',r)})}
    // const temp_doc =()=>{getSavedDoc(user, 0).then((r)=>{console.log('doc data',r)})}

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const delete_data_and_row =(id)=>{
        deleteData(user, tableData[id].id)
        delete tableData[id];
        const new_data = tableData.filter(val=>val!=null)
        
        setTableData(new_data);
    }

    const load_job_file = (e)=> {
        var reader = new FileReader()
        reader.readAsText(e.target.files[0])

        reader.onload = () =>{
            setFile(reader.result)
            
        }
    }

    const add_row =(fileTitle, file)=>{
         addSavedData(user,{title:fileTitle, data:file})
            .then( (r)=>{
                tableData.push({id:r, title:fileTitle})
                //console.log(r, 'table data',tableData)
                setTableContainer(<DataSettingsTable data={tableData}/>)
            })
    }

    const save_data =(id)=>{
        const new_data = tableData.filter(val=>val.id!=id)
        //console.log('new data', new_data)
        setTableData(new_data)
    }

    const create_table_row = (id, title, doc_id) => {
        return (
            <TableRow key = {id}>
                <TableCell>{id}</TableCell>
                <TableCell>{title}</TableCell>
                <TableCell>
                    <IconButton onClick={(r)=>{console.log('clicked')}}>
                        <VisibilityIcon/>
                    </IconButton>
                    <IconButton onClick={(r)=>{delete_data_and_row(id)}}>
                        <DeleteIcon/>
                    </IconButton>
                    <IconButton onClick={(r)=>{delete_data_and_row(id)}}>
                        <UploadFileIcon/>
                    </IconButton>
                </TableCell>
    
            </TableRow>
        )
    }

    

    const DataSettingsTable = (props) => {
        

        const rows = props.data.map((data, idx)=>{
            return create_table_row(idx, data.title, data.doc_id)
        })
    
    return (
            <TableContainer component={Paper}>
                <Table>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>   
        )
    }


    useEffect(() => {
        getSavedData(user).then((r)=>{
            setTableData(r.data)
        })
    }, [])

    useEffect(() => {
        if (file !=null){
            //console.log(fileTitle,tableData)
            add_row(fileTitle,file)
            setOpen(!open)
            setTableContainer(<DataSettingsTable data={tableData}/>)
        }
    }, [file])

    useEffect(() => {
        //console.log('updateing table data')
        if (tableData !=null){
            setTableContainer(<DataSettingsTable data={tableData}/>)
            
        }
    }, [tableData])

    return (
        <Container  maxWidth="md">
            <Box sx={{height:40}}></Box>
            <Typography variant='h4'>Data Settings</Typography>
            <Divider/>
            {tableData==null?<>empty</>:tableContainer}
            <Box sx={{height:5}}></Box>
            <Fab color="primary" onClick={handleClickOpen}>
                <AddIcon />
            </Fab>
                <UploadDialog 
                    open={open}
                    onClose={handleClose}
                    >
                        <DialogContent>
                <Stack>
                    <TextField
                        value={fileTitle}
                        onChange={(e)=>{setFileTitle(e.target.value)}}
                        autoFocus
                        autoComplete='off'
                        margin="dense"
                        id="name"
                        label="File Name"
                        variant="standard"
                    />
                    <Box sx={{height:5}}/>
                    <Button
                        variant="contained"
                        component="label"
                        >
                        Upload File
                        <input
                            onChange={load_job_file}
                            type="file"
                            hidden
                        />
                    
                    </Button>
                    </Stack>
                    </DialogContent>
                </UploadDialog>
            {/*<Button onClick={temp_add}> Add Button </Button>
            <Button onClick={temp_get}> Fetch </Button>
            <Button onClick={temp_del}> Delete Obl </Button>
    <Button onClick={temp_doc}> Get Doc </Button>*/}
        </Container>
        
    )
}