/* eslint-disable */
import React,{useState, useEffect} from 'react';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport, gridClasses, } from '@mui/x-data-grid';
import {  Box, CircularProgress,Stack,
    Stepper, Step, StepLabel, 
    TextField,InputAdornment, Button, 
    TableHead, Table, TableBody,TableCell,TableRow,LinearProgress } from '@mui/material';

    
import Autocomplete from '@mui/material/Autocomplete';
import SavedDataDropDown from '../customComponents/saved_data_dropdown'
import { ScrapeYelpRealEstate, YelpCategories} from '../data/api';
import SearchIcon from '@mui/icons-material/Search';

const yelp_url = 'https://www.yelp.com/developers/documentation/v3/all_category_list/categories.json'
/*

main difference between this and google scrale is the options will come from
a get request of the yelp options
*/


const handle_csv = (csv) => {
    var list = csv.split('\r\n')
    if (list.length <=1){
        list = csv.split('\r')
    }
    if (list.length <=1){
        list = csv.split('\n')
    }
    const cols = list[0].split(',')
    const vals = list.slice(1)

    return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {cols.map((val, idx)=>{return <TableCell align="center">{val}</TableCell>})}
                </TableRow>
            </TableHead>
            <TableBody>
            {vals.map((row,idx)=>{// vall is a row of csv val1,vas2, val3

             return (<TableRow>
                 {row.split(',').map((val, idx)=>{
                     return <TableCell align="center">{val}</TableCell>
                     })}
             </TableRow>)
            })}

            </TableBody>
      </Table>
    )
}

function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function YelpScrapeRealEstate(){
    const [yelpOptions,setYelpOptions] = useState();
    const [selectOption,setSelectOption] = useState();
    const [address,setAddress] = useState(null);
    const [tag,setTag] = useState(null);
    const [radius,setRadius] = useState(null);

    const [progress,setProgress] = useState(5);
    const [loading,setLoading] = useState(false);
    //Could use a big dict, but this feel easier ot com back to
    
    const [mapsData,setMapsData] = useState([]);
    const [mapsDataContainer,setMapsDataContainer] = useState([]);
    const [expectedReturnCount,setExpectedReturnCount] = useState([]);
    const [returnTable,setReturnTable] = useState(<></>);
    const [detailReturnTable,setDetailReturnTable] = useState(<></>);

    useEffect(()=>{
        YelpCategories().then((r)=>{         
            setYelpOptions(r.data.categories)
        })

    },[])

    useEffect(()=>{
        setSelectOption(selectOption)
    },[selectOption])

    useEffect(()=>{
        if (mapsData!= null){
        const new_ = mapsDataContainer.concat(mapsData)
        setMapsDataContainer(new_)
        }
    },[mapsData])


    useEffect(()=>{
        if (mapsDataContainer!= null){
            setProgress((expectedReturnCount/mapsDataContainer.length) * 100)
            update_return_table()
        }
        if(expectedReturnCount/mapsDataContainer.length==1){
            setLoading(false);
        }
    },[mapsDataContainer])


    //is this more or less clear than just calling it?
    const update_return_table = () =>{
        setReturnTable(createReturnTable(mapsDataContainer))
        try{
            setDetailReturnTable(createDetailReturnTable(mapsDataContainer))}catch(e){
            console.log(e)
        }
    }

    const execute_search = () => {

        setLoading(true);

        var data = {
            address:address || null,
            term:tag || null,
            radius:radius || null,
            categories:selectOption || null
          }
        console.log(address,tag,radius)
        // create a list of requests to send to backend
        ScrapeYelpRealEstate(data)
        .then((r)=>{
           console.log('DATA:',r.data);
           setMapsData(r.data)
           })
        .catch((e)=>{
            console.log('recieved error',e)
            setLoading(false)
        })
    }

 const createDetailReturnTable = (data_) =>{

        if (data_.length==0){
            return <></>
        }
        var data = data_.map((val)=>{
            if(val['details'].length!=0){
                var data = val['details']
                if(val.city==null||val.state==null){
                    data = data.map((x)=>{
                        x['lat']=val.latitude
                        x['lon']=val.longitude
                        return x
                    })
                    
                }
                return data
            }
        });
        
        data = data.filter(function( element ) {
            return element !== undefined;
         });
        if (data_[0].length==0){
            return <></>
        }

        var columns = [
            { field: 'id', headerName: 'ID', width: 70},
            { field: 'name', headerName: 'Name', width: 70} ,
            { field: 'distance', headerName: 'Distance', width: 70} ,
            { field: 'alias', headerName: 'Alias', width: 70} ,
            { field: 'price', headerName: 'Price', width: 70} ,
            { field: 'rating', headerName: 'Rating', width: 70} ,
            { field: 'review_count', headerName: 'Reviews', width: 70} ,
            { field: 'transactions', headerName: 'Transactions', width: 70} ,
            { field: 'categories', headerName: 'Categories', width: 70},
            { field: 'image_url', headerName: 'Image Url', width: 120},
            { field: 'is_closed', headerName: 'Is Closed?', width: 120},
            { field: 'zip', headerName: 'Zip', width: 120},
            { field: 'url', headerName: 'Yelp Url', width: 120},
            { field: 'match', headerName: 'Match', width: 40}
            
        ]
        // if(data_[0].state==null||data_[0].city==null){
        //     columns.push(
        //     {field: 'lat', headerName: 'Latitude', width: 120},
        //     { field: 'lon', headerName: 'Longitude', width: 120})

        // }
   

        data = data.flat(1)
        const rows = data.map((val, idx)=>{
            var transactions = [];
            var categories = val['categories'].map((val)=>{return val['title']});

            var city = val['location']['city'];
            var state = val['location']['state'];
            var zip = val['location']['zip_code'];

            var r = {
                    id:idx, 
                    name:val['name'],
                    alias:val['alias'],
                    distance:val['distance'],
                    price:val['price'],
                    rating:val['rating'],
                    review_count:val['review_count'],
                    transactions:transactions,
                    categories:categories.join(),
                    image_url:val['image_url'],
                    is_closed:val['is_closed'], 
                    city:city,
                    state:state,
                    zip:zip,
                    url:val['url'], 
                    match:val['match']
                 }
                 
            return r      
        })
        
        return (
            <div style={{ height: '100vh', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    components={{
                        Toolbar: CustomToolbar,
                      }}
                />
            </div>
        )
    
    }


    const createReturnTable = (data) =>{
        if (data.length==0){
            return <></>
        }
        const columns = [
            { field: 'id', headerName: 'ID', width: 70},
            { field: 'count', headerName: 'Count', width: 70} ,
            { field: 'match', headerName: 'Match', width: 70} ,
        ]

        console.log('table date',data);

        const rows = data.map((val, idx)=>{
            
        return {id:idx, count:val['count'], state:val['state'],match:val['match']  }
            
            
        })
        
        return (
            <div style={{ height: '600px', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    checkboxSelection
                    components={{
                        Toolbar: CustomToolbar,
                      }}
                />
            </div>
        )
    
    }

    return (
            <div className='left-1/4 h-screen '>
              <div className='left-1/4 absolute '>
                <Box sx={{height:100}}></Box>
              {/* <Autocomplete
                  multiple
                  value={selectOption}
                  onChange={(event, newValue) => {setSelectOption(newValue);}}
                  id="yelp-options"
                  options={yelpOptions}
                  getOptionLabel={(yelpOptions) => yelpOptions.title}
                  sx={{ width: 300 }}
                  renderInput={(params) => <TextField
                      {...params}
                      variant="standard"
                      label="Yelp Categories"
                      placeholder="..."
                  />}
              /> */}
            <Stack spacing={2}>
              <TextField 
                  value={address} 
                  onChange={(e)=>{setAddress(e.target.value)}} 
                  id="tag" 
                  label="Address" 
                  variant="standard" />
                <TextField 
                  value={tag} 
                  onChange={(e)=>{setTag(e.target.value)}} 
                  id="tag" 
                  label="Optional Search Name.." 
                  variant="standard" />
                <TextField 
                    value={radius} 
                    onChange={(e)=>{setRadius(e.target.value)}} 
                    id="radius" 
                    label="Optional Radius (Meters)" 
                    defaultValue={2000}
                    variant="standard" />
                <Button
                    onClick={()=>{execute_search()}}
                    variant="contained"
                    color="primary"
                  endIcon={<SearchIcon/>}
                >
                Search
              </Button>
            </Stack>
            </div>
            <div className='w-fill float h-screen'>
            {detailReturnTable}

            </div>
         </div>
        )
}


