

import {TextField, Stack} from '@mui/material';
import {useState, useEffect} from 'react';
import {OpenAI} from '../data/api';
import {Tab,    Slider} from '@mui/material';
import {CardContent,Card, Typography} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {InputLabel,MenuItem,Select,FormControl} from '@mui/material';
import Switch from '@mui/material/Switch';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import PersonIcon from '@mui/icons-material/Person';
/*

    Step one: select topic
    Step two: select data sources
    Interim step 3: select specific topic
    Step 4: provide prompt
    step 5 output
*/

// Topic: Cities
const topic = 'Cities'
const topics = [
            <MenuItem value={'cities'}>Cities</MenuItem>,
            // <MenuItem value={'zip code'}>Zip Codes</MenuItem>,
            // <MenuItem value={'activites'}>Activites</MenuItem>
        ]
const topic_instances = [
            <MenuItem value={'new york'}>New York</MenuItem>,
            <MenuItem value={'houston'}>Houston</MenuItem>

]
const dataSources = ['simplemaps', 'worldbank', 'census']

function Chat({chat,type}){

    if(type==='bot'){
        return <div className='break-words row-span-1'>
                    
                    <div className='text-lg  shadow-lg bg-slate-100 rounded-xl p-5 border-2 border-slate-300
                    text-right break-normal whitespace-pre-wrap '>
                            {chat}
                    </div>
                    <div className=' w-12 h-12 float-right'>
                        <SmartToyIcon />
                    </div>
                    <div className='h-8'></div>
                </div>
            } else {
        return <div className=' row-span-1'>
                <div className='text-lg shadow-lg  bg-slate-100 rounded-xl p-5 border-2 
                border-slate-300 break-normal whitespace-pre-wrap'>
                        {chat}
                </div>
                <div className='relative w-12 h-12'>
                 <PersonIcon/>
                </div>
        </div>
        }
        
    }
            
        


function ChatList({chatList}){
    console.log('ChatList: ', chatList)
    if(chatList.length !== 0){
        return <div className='grid grid-cols-1 w-4/12 h-screen overflow-auto'>
                    {chatList.map((c)=>{return <>
                        <Chat chat={c.text} type={c.type} />
                        <div className='h-2'></div>
                    </>})}
                
        </div>
    } else{
        return <></>
    }
}



export default function DataForest(){
    const [dataOptions, setDataOptions] = useState({});
    const [questionContext, setQuestionContext] = useState([]);
    const [tab, setTab] = useState('');
    const [topic, setTopic] = useState('');
    const [prompt, setPrompt] = useState('');
    const [loading, setLoading] = useState(false);
    const [temperature, setTemperature] = useState(100);
    const [wordCount, setWordCount] = useState(100);
    const [chat, setChat] = useState([]);
    const [topicInstance, setTopicInstance] = useState([]);
    
    function TabItems({data}){

        return <>
        </>
    }
    function DataTab({data}){
        
        if(tab === ''){
            setTab(Object.keys(data)[0])
        }
        return <TabContext value={tab}>
            <TabList 
                onChange={(e,v)=>{
                        setTab(v);}}
                >
            {Object.keys(data).map((r)=>{
                return <Tab label={r} value={r}/>
            })}
             </TabList>
            {Object.keys(data).map((r)=>{
                return <TabPanel value={r}>
                    <div className='grid grid-cols-3'>
                        {data[r].map((d)=>{
                            return <div className='flex flex-grow hover:border-b-2'>
                                    <div className='grow'>{d.column_name}</div>
                                    <Switch onChange={()=>{}} ></Switch>
                            </div>
                            })}
                    </div>
                </TabPanel>
            })}
        </TabContext>
    }
    
    const fetchDataOptions = (topic) => {
        setLoading(true);
        OpenAI({step:1})
            .then((r)=>{
                setDataOptions(r.data)
                

                setLoading(false);
            })
            .catch((e)=>{console.log(e);setLoading(false);})
    }
    
    const fetchAIAnswer = (topic) => {
        
        OpenAI({step:'prompt','text':prompt}) 
            .then((r)=>{
                console.log('chat', chat)
                chat.push({'text':r.data.choices[0].text, 'type':'bot'})
                setChat(chat)
                
                console.log('Chat info: ', chat.length,chat)
            })
            .catch((e)=>{console.log(e)})
    }

    return <div className='flex w-screen float-left -ml-96'>

        <div>
            <div className='h-12'></div>
            <FormControl fullWidth>
            
                    <InputLabel id="demo-simple-select-label">Select Topic</InputLabel>
                        <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={topic}
                        label="City"
                        onChange={(e)=>{setTopic(e.target.value)}}
                        >
                        {topics}
                        </Select>
                  
                </FormControl>
                <div className='h-4'></div>
                {topic?<>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Topic</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={topicInstance}
                            label="City"
                            onChange={(e)=>{setTopicInstance(e.target.value)}}
                            >
                            {topic_instances}
                            </Select>
                        </FormControl>
                </>:<></>}
                <div className='h-24'></div>
                <button 
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-12'
                onClick={()=>{fetchDataOptions()}}>Fetch Data Options
                </button>
                {loading ? <div>Loading</div> : <></>}
                {Object.keys(dataOptions).length > 0 ? <>
                    <div className='h-12'></div>
                    <DataTab data={dataOptions}/>
                    </>:<></>}
            </div>
        <div className='w-32'></div>
        <div className='h-screen border-l-2 border-slate-400'></div>
        <div className='w-32'></div>
        <Stack>
            <div className='h-12'></div>
            <div>
            <TextField id="standard-basic" label="Prompt" variant="outlined" 
                onChange={(e)=>{setPrompt(e.target.value)}} value={prompt}/>
            </div>
            <div className='h-12'></div>
            <div className='flex'>
                <button 
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-12'
                    onClick={()=>{
                        console.log([...chat,{'type':'human','text':prompt}])
                            setChat([...chat,{'type':'human','text':prompt}])
                            fetchAIAnswer()}}>Ask the AI
                </button>
                <div className='w-12'></div>
                <div>
                    <TextField id="outlined-basic" 
                        label="Return Word Count" 
                        variant="outlined" 
                        value={wordCount}
                        onChange={(e)=>{setWordCount(e.target.value)}} />
                </div>
                <div className='w-12'></div>
                <div>
                    <div>AI Temperature</div>
                    <Slider value={temperature} onChange={(e)=>{setTemperature(e.target.value)}} min={0} max={200}/>
                    <div>{temperature/100}</div>
                </div>
            </div>
            <div>
                <div>
                    <ChatList chatList={chat}/>
                </div>
            </div>
        </Stack>
    </div>
}