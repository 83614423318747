import React from 'react';
import {useEffect, useContext} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import myContext from './mycontext';
import {Navigate} from 'react-router-dom';
import {getFirestore} from 'firebase/firestore';
import { getAuth, signOut } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
// TODO: Replace the following with your app's Firebase project configuration
import Geocode from "react-geocode";

Geocode.setApiKey(process.env.REACT_APP_GMAPS_API_KEY);
const firebaseConfig = {
  apiKey: process.env.REACT_APP_AXIOS_API_KEY,
  authDomain: process.env.REACT_APP_AXIOS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_AXIOS_PROJECT_ID,
  storageBucket:process.env.REACT_APP_AXIOS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_AXIOS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_AXIOS_APP_ID,
  measurementId: process.env.REACT_APP_AXIOS_MEASUREMENT_ID
};

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/Dashboard',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
  };



firebase.initializeApp(firebaseConfig);
var functions = getFunctions();
// const db = getDatabase();
const db = getFirestore();


function LogOut() {
  const auth = getAuth();
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    console.log('error signing out:', error)
  });
}

function Login() {

  const {user, setUser} = useContext(myContext);

  useEffect(()=>{
    
    if(user!=null){
      //console.log('logging in')
      setUser(user);
    }

    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if(user==null){
        //console.log('not logged in', user)

      }else{
        //console.log('logged in ', user)
        setUser(user);
      }
      
      
    });
    return () => unregisterAuthObserver();
  },[])

  
  return (

    <div> {user==null ? 
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
        :
        <Navigate to='/Dashboard'></Navigate>}
      </div>

  );
}
export default Login;
export {firebase, StyledFirebaseAuth, db, functions, LogOut};
