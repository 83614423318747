import React, {useState,useEffect} from 'react';
import {Container, Stack, Box, TextField, Button, Typography} from '@mui/material'
import withRoot from '../withRoot';
import AppAppBar from './AppAppBar';


function Contact(){

    const [formData, setFormData] = useState({});

    useEffect(()=>{

    },)

    const handle_form = (r) => {
        console.log(r.target.id, r.target.value)
        //formData.update({r.target.id: r.target.value})
        setFormData()
        // use r.target.id and r.target.data to make one object with all info
    }

    const sumbit_form =(r) =>{
        console.log('clicked')
    }
    
    const Inputs = () =>{


        return (
            <><Typography variant='h5'> Questions?</Typography>
            <Box sx={{ p: 2, border: '1px solid', 'border-color':'secondary.main'}}>
                
                <Stack spacing={2}>
                    <TextField id='name' required label='Name' margin='normal' onChange={handle_form}/>
                    <TextField id='email' required label='Email' margin='normal' onChange={handle_form}/>
                    <TextField id='name' label='Company' margin='normal' onChange={handle_form}/>
                    <TextField id='question' label='Question' margin='normal' rows={6} maxRows={10}
                        defaultValue="What's Gucci?" onChange={handle_form}/>
                    <Button onClick={sumbit_form} backgroundColor='secondary.main'>
                        Submit
                    </Button>
                </Stack>
            </Box></>
        )
    }


    return(
        <>
        <AppAppBar/>
        <Box sx={{height:70}}/>
        <Stack>
            <Container maxWidth="sm">
                <Inputs/>
            </Container>
        </Stack>
          
        </>
    )
}

export default withRoot(Contact)