/* eslint-disable */
import {functions, db} from '../myFirebase'
import { httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import axios from 'axios'; 
import { collection, addDoc , serverTimestamp  } from "firebase/firestore"; 


/*
need to think carefully abot when to make these fetch the data, and when
to allow them to preparse the data some the component using it can
request in batches, at once, etc
*/

function RecordSearch(function_name, request){
    const auth = getAuth();
    const user = auth.currentUser;
    addDoc (collection(db, 'logs'),{
        uid:user.uid,
        datetime: serverTimestamp(),
        function:function_name,
        request: request
    })
}


function OpenAI(dict){

    const config = {header:{"Content-Type":"application/json"}}
    RecordSearch('OpenAI', dict)
    return axios.post('/open_ai',dict, config)
}   
function StripeCheckout(uid, product_ids){

    const config = {header:{"Content-Type":"application/json"}}
    return axios.post('/checkout', config)
}   

function StripeProducts(){

    const config = {header:{"Content-Type":"application/json"}}
    return axios.post('/checkout?mode=products', config)
}  

function YelpCategories(){ //{city:"Houston, TX",job:["Janitor","Chemical Engineer"]}

    const config = {header:{"Content-Type":"application/json"}}
    return axios.post('/Yelp-Options', config)

}

function OptionsScanner(dict){ //{city:"Houston, TX",job:["Janitor","Chemical Engineer"]}
    const config = {header:{"Content-Type":"application/json"}}
    RecordSearch('OptionsScanner', dict)
    return axios.post('/OptionsScanner', dict, config)
        
}

function ScrapeYelp(dict){ //{city:"Houston, TX",job:["Janitor","Chemical Engineer"]}
    const config = {header:{"Content-Type":"application/json"}}
    RecordSearch('ScrapeYelp', dict)
    return axios.post('/Yelp-Scrape', dict, config)
        
}


function ScrapeYelpRealEstate(dict){ //{city:"Houston, TX",job:["Janitor","Chemical Engineer"]}
  const config = {header:{"Content-Type":"application/json"}}
  RecordSearch('ScrapeYelpRealEstate', dict)
  console.log('VALUES',process.env.REACT_APP_GOOGLE_CLOUD_TOKEN)
  return axios.post('/Yelp-Scrape-RealEstate', dict, config)
      
}
// getting same error when manually called in browser
function ScrapeIndeedJobs(dict){ //{city:"Houston, TX",job:["Janitor","Chemical Engineer"]}
    const config = {header:{"Content-Type":"application/json"}}
    RecordSearch('ScrapeIndeedJobs', dict)
    return axios.post('/Scrape-Indeed', dict, config)
        
}

function ScrapeMapsGFD(dict){ //{city:"Houston, TX",job:["Janitor","Chemical Engineer"]}
    const config = {header:{"Content-Type":"application/json"}}
    RecordSearch('ScrapeMapsGFD', dict)
    return axios.post('/Google-Maps-Scrape-GFD', dict, config)
        
}

function ScrapeUhaul(dict){ // {"to":"Austin, TX","from":"Houston, TX","date":"02/04/2022"}
    const config = {header:{"Content-Type":"application/json"}}
    return axios.post('/Uhaul-Scrape', dict, config)
        
}

function ScrapeGeneral(dict){ // {"url":"Austin, TX"}
    RecordSearch('ScrapeGeneral', dict)
    const config = {header:{"Content-Type":"application/json"}}
    return axios.post('/General-Scrape', dict, config)
        
}

function ScrapeBtsFlights(dict){ // {"url":"Austin, TX"}
    RecordSearch('ScrapeBtsFlights', dict)
    const config = {header:{"Content-Type":"application/json"}}
    return axios.post('/BTS-Scrape', dict, config)
        
}
export {ScrapeYelpRealEstate,ScrapeIndeedJobs,ScrapeMapsGFD, ScrapeUhaul, ScrapeYelp, YelpCategories, ScrapeGeneral, ScrapeBtsFlights, OptionsScanner,OpenAI};