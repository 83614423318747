import {getApps} from '../data/db';
import React, {useContext, useState, useEffect} from 'react';
import myContext from '../mycontext';
import {Button,Container } from '@mui/material';
import {ScrapeIndeedJobs} from '../data/api'
import SavedDataDropDown from '../customComponents/saved_data_dropdown'

export default function Test(){
    const {user,_} = useContext(myContext);
    const [val, setVal] = useState();
    const [fileData, setFileData] = useState();

    const testDb = () =>{
        getApps(user).then((r)=>{
            setVal(r[1].app_alias);
        });
    }
    const testAPI = () =>{
        const r = ScrapeIndeedJobs({city:"Houston, TX",job:["Janitor","Artist"]});
        console.log('in component',r.then((r)=>{console.log(r)}));
    }
    useEffect(() => {
        console.log('in Parent',fileData)
    }, [fileData])

    return <>
            <Button onClick={testAPI}>Test</Button>
            <>{val}</>
            <SavedDataDropDown setData={setFileData} button= {<Button>Hello!</Button>}>

            </SavedDataDropDown>
            </>
}