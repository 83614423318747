/* eslint-disable */
import React, {useContext, useEffect, useState} from 'react';
import myContext from './mycontext';
import {Tab, Tabs, Box} from '@mui/material';
import MyAvatar from './customComponents/avatar';
import {GetUserComponentData} from './data/api'
import AppBarCustom from './appbar';
import VerticalTabls from './customComponents/tabs';
import AppsIcon from '@mui/icons-material/Apps';
import GroupsIcon from '@mui/icons-material/Groups';
import DataObjectIcon from '@mui/icons-material/DataObject';
import DataSettings from './customComponents/data_settings';
import { Navigate } from 'react-router-dom';

const settings_tabs = [
    {title:'Apps',icon:<AppsIcon/>, component:<VerticalTabls/>},
    {title:'Data Settings',icon:<GroupsIcon/>, component:<DataSettings/>},
    {title:'Groups',icon:<DataObjectIcon/>, component:<div>Groups</div>},
    
  ]
  const convertSettingsComponents=(apps) =>{
    //take in a list of valid ids and aliases
  
  return Object.keys(apps).map((val, idx)=>
  {   
    return <Tab 
          key={apps[val].id} 
          label={apps[val].title} //label from user settings
          id={apps[val].id}  
          icon={apps[val].icon}// id for local component
          >
              {apps[val].component}
          </Tab>
  }
  )
}

const unhide_tab = (value) =>{ 
    const title = settings_tabs[value].title

    const tabs = settings_tabs.map((row)=>{

        if(title== row.title){
            return (
            <div 
                style={{display:'block', width:'100%'}}>
                {row.component}
            </div>)
        }else{
            return (
            <div 
            style={{display:'none', width:'100vw'}}>
            {row.component}
            </div>)
        }
       
    })

    return tabs
}

function Dashboard(){
    const {user, _} = useContext(myContext);
    const [value, setValue] = useState(0);
    const [tabs, setTabs] = useState();
    const [tabContent,setTabContent] = useState();

    useEffect(() => {
        setTabs(convertSettingsComponents(settings_tabs));
    }, [])
    
    useEffect(()=>{
        setTabContent(unhide_tab(value))
    }
    ,[value])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
  
        return<>
            {user==null?<Navigate to='/SignIn'></Navigate>:<></>}
            <AppBarCustom avatar={<MyAvatar/>}> </AppBarCustom>
            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}>
            <Tabs
            value={value}
            onChange={handleChange} 
            orientation="vertical">
                {tabs}
            </Tabs>
                {tabContent==null? <div>Loading...</div>:tabContent}

            </Box>
            </>
}

export default Dashboard