import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';

const backgroundImage =
  './dreamstime_xl_63122748.jpg';
//Illustration 63122748 © Tawatchai Khid-arn | Dreamstime.com

export default function ProductHero() {
  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#7fc7d9', // Average color of the background image.
        backgroundPosition: 'center',
      }}
    >
      {/* Increase the network loading priority of the background image. */}
      <img
        style={{ display: 'none' }}
        src={backgroundImage}
        alt="increase priority"
        meta="Illustration 63122748 © Tawatchai Khid-arn | Dreamstime.com"
      />
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Bridge Data To Your Work
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        sx={{ mb: 4, mt: { sx: 4, sm: 10 } }}
      >
       Your Data, Our Proprietary Data, All In A Convientent Data-Bridge Board
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        component="a"
        href="/SignIn"
        sx={{ minWidth: 200 }}
      >
      <Typography variant="body2" color="text.main" >
        Try it Out!
      </Typography>
      </Button>
      <Typography variant="body2" color="text.main" sx={{ mt: 2 }}>
        
      </Typography>
    </ProductHeroLayout>
  );
}
