import {db} from '../myFirebase';
import {doc, getDoc,addDoc, collection, setDoc, arrayUnion, updateDoc, arrayRemove} from 'firebase/firestore'

const account_search_data = collection(db,'account-search-data');
const stored_data = collection(db,'saved-data');
const apps= collection(db,'apps');

async function getSavedData(user){
    const user_data_settings = doc(account_search_data,'/'+user.uid);
    const user_data = await getDoc(user_data_settings);

    if (user_data.exists()){
        const data = user_data.data();
        return data
    } else{
        //run app creatoin here
        setDefaultData(user);
        return null
    };
}
async function getSavedDoc(user, doc_id){
    const doc_ref = doc(stored_data, '/'+ doc_id);
    //console.log(doc_id)
    const _doc = await getDoc(doc_ref)
    if (_doc.exists()){

        return _doc.data()
    } else{
        return 'Not Found'
    }
    
}

async function setDefaultData(user){
    //this will need to be verified on the backed that the value are the ones selected
    const data = {
                data:[
                    {title:'Demo Indeed Data Jobs', id:0},
                    {title:'Demo Indeed Data Cities', id:1},
                    {title:'Demo Google Data Cities', id:2}
                ]
            };

    const _ = await setDoc(doc(account_search_data, '/'+ user.uid),data);
}

//can also be used as an update method
async function addSavedData(user, data){
    // data = > {title:'Test', data:"1234"}
    // const stored_doc_ref = doc(stored_data);

    const saved_doc = await addDoc(stored_data,{data:data.data});

    const doc_ref = doc(account_search_data, '/'+ user.uid);
    
    const user_data = {title:data.title, id:saved_doc.id};
    //this will need to be verified on the backed that the value are the ones selected
    await updateDoc(doc_ref, {data:arrayUnion(user_data)});

    return saved_doc.id
}

async function deleteData(user, id){
    // const resp_data = {data:[data]};
    const doc_ref = doc(account_search_data, '/'+ user.uid);
    
    const data = await getDoc(doc_ref)
    if (data.exists()){

        const saved_files = data.data();
        
        const to_delete = saved_files.data.map((file)=>{
            if(file.id==id){
                return file
            }else{
                return false;
            }
        }).filter(val=>val!=false)[0]
        //console.log('to del',to_delete)

        return updateDoc(doc_ref, {data:arrayRemove(to_delete)});
    } 
    
    //this will need to be verified on the backed that the value are the ones selected
}

async function getData(data_id){

    const user_data_settings = doc(stored_data,'/'+data_id);
    const data = await getDoc(user_data_settings);

    if (data.exists()){
        const data = data.data();
        return data
    } 

    
}

export {getSavedData, getData, addSavedData, deleteData, getSavedDoc};