/* eslint-disable */
import * as React from 'react';
import { useContext,useState,useEffect } from 'react';
import {Button,Container, Skeleton} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import myContext from '../mycontext';
import GoogleScraper from '../tabs/GoogleScrape';
import IndeedJobs from '../tabs/IndeedJobs'
import UhaulScrape from '../tabs/UhaulScrape'
import YelpScrape from '../tabs/YelpScrape'
import GeneralScrape from '../tabs/GeneralScrape'
import BtsScrape from '../tabs/BtsScrape';
import Test from '../tabs/test'
import OptionsScanner from '../tabs/OptionsScanner'
import DataForest from '../tabs/DataForest'
import YelpScrapeRealEstate from '../tabs/YelpScrapeRealEstate'
import {getApps} from '../data/db';
//create component for tabs to be skeletons

//sorta weird, but these ids match to ids in firestire to dive conneciton
const components = [
  {id:420, title:'Tests',component:<Test/>},
  {id:0, title:'Google Scrape',component:<GoogleScraper/>},
  {id:1, title:'Wikipedia Analysis',component:<IndeedJobs/>},
  {id:2, title:'Google News',component:<>Google</>},
  {id:3, title:'Indeed Jobs',component:<IndeedJobs/>},
  {id:4, title:'Uhaul Scraper',component:<UhaulScrape/>},
  {id:5, title:'Yelp Scraper',component:<YelpScrape/>},
  {id:6, title:'General Scraper',component:<GeneralScrape/>},
  {id:7, title:'BTS Scraper',component:<BtsScrape/>},
  {id:8, title:'Options Scanner',component:<OptionsScanner/>},
  {id:9, title:'Data Forest WIP',component:<DataForest/>},
  {id:10, title:'YelpScrapeRealEstate',component:<YelpScrapeRealEstate/>}
]

const find_component = (id) => {
  const tab =  components.map((val,idx)=>{
    if(val.id==id){
      return val
    }
  }).filter(val => val != null)[0]
  return tab.component
};

const unhide_tab = (value) =>{ 

  const tabs = components.map((row)=>{

      if(value== row.id){
          return (
          <div 
              style={{display:'block', width:'100%'}}>
              {row.component}
          </div>)
      }else{
          return (
          <div 
          style={{display:'none', width:'100vw'}}>
          {row.component}
          </div>)
      }
     
  })

  return tabs
}

const empty_tabs = () =>{

  return(
    
      <Skeleton variant="rectangular" width={175} height={50} /> 
    
  )
}

export default function VerticalTabs() {
  const [value, setValue] = useState(0); // this is where init component set
  const {user,_} = useContext(myContext);

  //was oging to save whoel react apps in dub but was taking wayyyto long t implement
  // const [db,setDb] = useState();
  const [tabs,setTabs] = useState(null);
  const [settingsTabs,setSettingsTabs] = useState(null);
  const [tabContent,setTabContent] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const convertComponents=(apps) =>{
      //take in a list of valid ids and aliases
    
    return Object.keys(apps).map((val, idx)=>
    {   
      return <Tab 
            label={apps[val].app_alias} //label from user settings
            id={apps[val].app_id} 
            value={apps[val].app_id}// id for local component
            />
    }
    )
  }
  
  useEffect(()=>{

    //generate clickable tabs when user is verified
    
      if (components != null) {
          getApps(user).then((apps)=>{
            //retry as we would have created default values
            console.log(apps)
            if (apps==null){
              getApps(user).then((apps)=>{
                setTabs(convertComponents(apps));
              })
              
            }else{
              setTabs(convertComponents(apps));
            }
          });
         //setTabs(convertComponents(components))
          
      } else {
          console.log("No data available");
      }
    
  }
  ,[user])

  useEffect(()=>{
      if(components != null){

          const t  = components.map(
            (val,idx)=>{return <div id={val.id}>{val.component}</div>}
            )
          setTabContent(unhide_tab(value))
        }
    }
        ,[value])

  return (

    // coudn't get dynamic working, going to manually 
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 700 }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
      >
        {tabs==null? <div>{empty_tabs()}</div>:tabs}
      </Tabs>
      <Container maxWidth="md">
        {tabContent==null? <></>:tabContent}
        </Container>
    </Box>
  );
}
