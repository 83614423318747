/* eslint-disable */
import React,{useState, useEffect} from 'react';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport, gridClasses, } from '@mui/x-data-grid';
import {Container, LinearProgress, CircularProgress,
        Grid, Typography, Button, Divider,Switch, FormControlLabel,} from '@mui/material';
import SavedDataDropDown from '../customComponents/saved_data_dropdown'
import Box from '@mui/material/Box';
import {ScrapeIndeedJobs} from '../data/api';

const steps = [
  'Select master blaster campaign settings',
  'Create an ad group',
  'Create an ad',
];

const city_columns  = [
    {field:'id', headerName:'ID', width:40},
    {field:'data', headerName:'City, State', width:120}
]

const job_columns = [
    {field:'id', headerName:'ID', width:40},
    {field:'data', headerName:'Job', width:120}

]

const scrape_columns = [
    {field:'id', headerName:'ID', width:40},
    {field:'city', headerName:'City', width:120},
    {field:'job', headerName:'Job', width:200},
    {field:'count', headerName:'Count', width:120}

]

function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const createTable = (columns, data) =>{
    //const list = csv.split(',')
    const rows = data.map((val, idx)=>{
        return {id:idx,data:val }
    })
    return (
        <div style={{ height: '600px', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
            />
        </div>
    )

}

const createResponseTable = (data) =>{

    const rows = data.map((val, idx)=>{
        return {id:idx, city:val['city'], job:val['job'], count:val['count'] }
    })

    return (
        <div style={{ height: '600px', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={scrape_columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
                components={{
                    Toolbar: CustomToolbar,
                  }}
            />
        </div>
    )

}

export default function IndeedJobs() {
    const [manual, setManual] = useState(false);
    const [cityTable, setCityTable] = useState(null);
    const [jobTable, setJobTable] = useState(null);
    const [cityData, setCityData] = useState(null);
    const [jobData, setJobData] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [scrapeData, setScrapeData] = useState([]);
    const [scrapeDataContainer, setScrapeDataContainer] = useState([]);
    const [scrapeDataTable, setScrapeDataTable] = useState(null);
    const [fetchedCity, setFetchedCity] = useState([]);
    const [fetchedCityContainer, setFetchedCityContainer] = useState([]);
    const [cityError, setCityError] = useState([]);
    const [cityErrorContainer, setCityErrorContainer] = useState([]);
    const [progress, setProgress] = useState(0);

    const load_job_file = (e)=> {
        var reader = new FileReader()
        reader.readAsText(e.target.files[0])

        reader.onload = () =>{
            const data = reader.result.split('\r\n');
            setJobData(data);
            setJobTable(createTable(job_columns,data ))
        }
    
    }

    const load_city_file = (e)=> {
        var reader = new FileReader()
        reader.readAsText(e.target.files[0])
        reader.onload = () =>{
            const data = reader.result.split('\r\n');
            setCityData(data);
            setCityTable(createTable(city_columns,data));
        }
    
    }

    const ScrapeInterface = () =>{


        return (<>
            <Box sx={{height:100, display: 'flex' }}  ></Box>
                <Button
                        onClick={execute_scrape}
                        variant="outlined"
                        disabled={disableButton}>
                    Run Scraping Job
                </Button>
                <Box sx={{height:25 }}  ></Box>
                {disableButton ?  <><CircularProgress/> <LinearProgress variant="determinate" value={progress} /> </>:<></>}
            <Box sx={{height:50}}></Box>
        </>)
    }

    const prepare_requests = () =>{

        if (typeof cityData == 'string'){
            const data= cityData.split('\r\n').map((val)=>{
                return {'city':val,job:jobData}
            })
            console.log('Job Data', jobData)
            return data 
        }else{
        const data= cityData.map((val)=>{
            return {'city':val,job:jobData}
        })
        return data
    }
       
    }

    const execute_scrape = () =>{
        setDisableButton(true);
        setProgress(10)
        const prepare_requests_data =prepare_requests();

        prepare_requests_data.map((val)=>{
            //return xios requst that hasn't been exec
            ScrapeIndeedJobs(val).then((r)=>{ 
                const new_data = scrapeData.concat(r.data);
                
                setScrapeData(new_data);
                setFetchedCity(val['city']);
                
            }).catch((r)=>{
                setCityError(val['city'])
            })
        })
    }
    //tehre is a better way to do this, but this is easy for now
    useEffect(() => {
         
        if(cityError.length!=0){
            setCityErrorContainer(cityErrorContainer.concat(cityError))
        }
    }, [cityError])

    useEffect(() => {
         
        if(fetchedCity.length!=0){
            setFetchedCityContainer(fetchedCityContainer.concat(fetchedCity))
        }
    }, [fetchedCity])

    useEffect(() => {
         
         if(scrapeData.length!=0){
            setScrapeDataContainer(scrapeDataContainer.concat(scrapeData))
            if(scrapeDataContainer.length==0){
                setScrapeDataTable(createResponseTable(scrapeData))
            }else{
            setScrapeDataTable(createResponseTable(scrapeDataContainer.concat(scrapeData)));
            }
         }
     }, [fetchedCity])

     useEffect(() => {
         if (jobData!=null){
            console.log('setting job data')
            setJobTable(createTable(job_columns,jobData.split('\r\n')  ))
         }
         
     }, [jobData])

     useEffect(() => {
        if (cityData!=null){
            setCityTable(createTable(city_columns,cityData.split('\r\n') ))
        }
    }, [cityData])

     //update Progress Bar
     useEffect(()=>{
        if (cityData!=null){
            setProgress(((cityErrorContainer.length+fetchedCityContainer.length+1) /cityData.length)*100)
            if (fetchedCityContainer.length+1 + cityErrorContainer.length >= cityData.length){
                setDisableButton(false)
            }else if (typeof cityData == "string"){
                if (fetchedCityContainer.length+1 + cityErrorContainer.length >= cityData.split('\r\n').length){
                    setDisableButton(false)
                }
            }
        }
    

     },[cityError,fetchedCity])

    const FileSearch = () =>{
        return( <> 
        <Box sx={{height:100}}></Box>  
           <Grid container spacing={4}
                justifyContent="center"
                alignItems="center">
            <Grid item xs={5}>
            <SavedDataDropDown 
                setData={setCityData}
                button={
                    <Button
                        variant="contained"
                        component="label"
                    >Select Cities
                    <input
                            onChange={load_city_file}
                            type="file"
                            hidden
                        />
                    </Button>
                }/>
                
           
                </Grid>
                    <Grid item xs={2}>
                        {/*<Divider orientation='vertical'/>*/}
                    </Grid>
                <Grid item xs={4}>
                <SavedDataDropDown 
                setData={setJobData}
                button={
                    <Button
                    variant="contained"
                    component="label"
                    >Select Jobs
                    <input
                        onChange={load_job_file}
                        type="file"
                        hidden
                    />

                    </Button>}/>
                </Grid>
                    
                <Grid item  xs={5}>
                    <Box sx={{ height: 600, width: 250 }}>
                        {cityTable==null?<></>:<div>{cityTable}</div>}
                    </Box>
                </Grid>
                <Grid item xs={2}>
                        {/*<Divider orientation='vertical'/>*/}
                </Grid>
                <Grid item  xs={5}>
                    <Box sx={{ height: 600, width: 250 }}>
                        {jobTable==null?<></>:<div>{jobTable}</div>}
                    </Box>
                </Grid>
           </Grid>
           </>
           )
    }

  return (
    <div>
        {manual?<>manual</>:<FileSearch/>}
        {jobData!=null&&cityData!=null?<div><ScrapeInterface/></div>:<div></div>}
        {scrapeData.length==0?<></>:<div>{scrapeDataTable}</div>}
    </div>
  );
}