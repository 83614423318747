
/* eslint-disable */
import {ScrapeUhaul} from '../data/api'
import {GetLatLon} from '../data/gmaps';
import React,{useState, useEffect} from 'react';
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExport, gridClasses, } from '@mui/x-data-grid';
import {  Box, Grid,CircularProgress,Stack,Switch, FormControlLabel,
    TextField,InputAdornment, Button, 
    TableHead, Table, TableBody,TableCell,TableRow,LinearProgress } from '@mui/material';
import SavedDataDropDown from '../customComponents/saved_data_dropdown'



const CreateReturnTable = ({data}) =>{
    if (data.length==0){
        return <></>
    }
    const columns = [
        { field: 'id', headerName: 'ID', width: 70} ,
        { field: 'to', headerName: 'To', width: 125} ,
        { field: 'from', headerName: 'From', width: 125},
        { field: 'date', headerName: 'Date', width: 125},
        { field: 'truck', headerName: 'Truck', width: 125},
        { field: 'flat rate', headerName: 'Flat Rate', width: 125},
        { field: 'per mile rate', headerName: 'Per Mile Rate', width: 125} 
    ]

    const rows = data.map((row, idx)=>{
        var d_data = {}
        // return {id:idx, count:val['count'], query:val['query'] }
        columns.map((val,idx)=>{

            return d_data[val.field] = row[val.field]
        })
        d_data['id']=idx
        return d_data
    })
    
    return (
        <div style={{ height: '600px', width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                checkboxSelection
                components={{
                    Toolbar: CustomToolbar,
                  }}
            />
        </div>
    )

}

function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const handle_csv = (csv) => {
    const list = csv.split('\r\n')
    const cols = list[0].split(',')
    const vals = list.slice(1)

    return (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    {cols.map((val, idx)=>{return <TableCell align="center">{val}</TableCell>})}
                </TableRow>
            </TableHead>
            <TableBody>
            {vals.map((row,idx)=>{// vall is a row of csv val1,vas2, val3
            //console.log(row)
             return (<TableRow key = {idx}>
                 {row.split('",').map((val, idx)=>{
                     return <TableCell align="center">{val.replace('"','')}</TableCell>
                     })}
             </TableRow>)
            })}

            </TableBody>
      </Table>
    )
}
export default function UhaulScrape(){
    const [uhaulData, setUhaulData] = useState();
    const [apiData, setApiData] = useState();
    const [apiDataContainer, setApiDataContainer] = useState([]);

    useEffect(()=>{
        if(apiData!=null){
            const new_data = apiDataContainer.concat(apiData)
            setApiDataContainer(new_data)
        }
    },apiData)

    const load_file = (e)=> {
        var reader = new FileReader()
        console.log(reader.readAsText(e.target.files[0]))
        reader.onload = () =>{
            
            setUhaulData(reader.result)
        }
    
    }

    const SaveDataButton = () =>{

        return <SavedDataDropDown
        setData={setUhaulData}
        button={
        <Button
            variant="contained"
            component="label"
            >
            Upload Uhaul File
            <input
                onChange={load_file}
                type="file"
                hidden
            />
        </Button>}
        />
    }

    const scrape_and_save = val =>{
        ScrapeUhaul(val).then((r)=>{
            setApiData(r.data)
        })
    }
    const execute_search = () =>{
        const formatted = format_data(uhaulData)
        console.log(formatted)
        formatted.map((val, idx)=>{
             const delay  = idx * 3 * 1000
            setTimeout(scrape_and_save(val),delay)
        })

        
    }
    const format_data = (data) =>{
        const list = data.split('\r\n')
        const cols = list[0].split(',')
        //ugly but just loops through the vals, split by ", then removes the other "
        const vals = list.slice(1).map((val)=>{return val.split('",').map((val)=>{return val.replace('"','')})})
        //zips cols and vals ro record {c1:v1,c2:v2}
        const formatted = vals.map((val)=>{
            var d = {}
            val.map((i,idx)=>{ d[cols[idx]]=i})
            return d
        })

        return formatted
    }
   
    const ExecuteButton = () =>{
        

        return <Button
                variant="contained"
                component="label"
                onClick={execute_search}
                >
                Execute Search
                
        </Button>
    }


    
    return (
        <div>
            <Box sx={{height:100}}></Box>
            <SaveDataButton/> <ExecuteButton/>
            <Box sx={{height:25}}></Box>
            {uhaulData==null?<></>:handle_csv(uhaulData)}
            <Box sx={{height:25}}></Box>
            {apiDataContainer==null?<></>:<CreateReturnTable data = {apiDataContainer}/>}
        </div>
    )
}