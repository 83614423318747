import * as React from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '../components/Typography';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};
const styles = {
  largeIcon: {
    width: 60,
    height: 60,
  },
};

const ValuesBox = (props) =>{
return (
    <Grid item xs={12} md={4}>
      <Box sx={item}>
        <Box
          component="img"
          src={props.src}
          sx={{ height: 55 }}
        />
        {props.icon}
        <Typography variant="h6" sx={{ my: 5 }}>
          {props.title}
        </Typography>
        <Typography variant="h5">
          {
           props.row1
          }

          {
            props.row2
          }
        </Typography>
      </Box>
    </Grid>
)
}

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: 'flex', overflow: 'hidden', bgcolor: 'secondary.light' }}
    >
      <Divider></Divider>
      <Container sx={{ mt: 15, mb: 30, display: 'flex', position: 'relative' }}>
      
        <Grid container spacing={5}>
        <ValuesBox 
            src='/indeed-logo.png' 
            title ="Indeed Jobs by City" 
            row1='Indeed posts jobs for thousands of cities, we can collect that data,'
            row2 =' and aggregate it to aid business decisions.' />
          <ValuesBox 
            src='/google-logo.png' 
            title ="Google Maps Data" 
            row1="How many coffee shops are there in Portland? Let's find out. "
            row2 ='What are the names of all the Bowling Alleys in Austin? Come ask us.'/>
         <ValuesBox 
            src="/wikipedia-logo.png" 
            title ="Wikipedia Crawlers" 
            row1="Wikipedia's goal is to provide free information, let's get the slices you need."/>
          
          
          {/* --------------------------------------------------------------*/}
          <ValuesBox 
            src='/U-Haul_logo.png' 
            title =" U-Haul Intercity Pricing" 
            row1='How much does it cost to rent a U-Haul from Austin, TX to San Diego, CA? What about the other direction'
            row2 ='We can find prices from any combination of cities.' />      
          <ValuesBox 
            src='Openstreetmap_logo.png' 
            title ="Open Street Maps" 
            row1='Where are all the public water fountains in New York?'
            row2 ='How where is all the car parking and bicycle parking in Denver?' />
          <ValuesBox 
            src=''
            title ="" 
            row1=''
            row2 ='' />
          {/*<ValuesBox 
            src='/Analytics.png'
            title ="If you can see it, we can get it." 
            row1='The web was made to share data between people, lets keep it up.'
          row2 ='' />  */}
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
