import { ScrapeBtsFlights } from "../data/api"
import { useEffect, useState } from "react"
import { airports, airlines, statistics } from "../data/bts_static_data"
import { Autocomplete, TextField, FormGroup,FormControlLabel,Checkbox, Button } from "@mui/material"
import CsvTable from "../customComponents/csv_table"

const csv =",Carrier Code,Date (MM/DD/YYYY),Flight Number,Tail Number,Destination Airport,Actual departure time,Departure delay (Minutes)\n0,DL,01/01/2021,507,N3772H,SLC,17:29,29\n1,DL,01/01/2021,1297,N981AT,ATL,12:57,1\n2,DL,01/01/2021,1646,N132DU,DTW,06:00,0\n3,DL,01/01/2021,2060,N398DA,ATL,06:13,-2\n4,DL,01/01/2021,2144,N927AT,ATL,14:33,-7\n5,DL,02/01/2021,474,N3759,SLC,00:00,0\n6,DL,02/01/2021,1201,N990AT,ATL,00:00,0\n7,DL,02/01/2021,1255,N607AT,ATL,00:00,0\n8,DL,02/01/2021,1297,N948AT,ATL,00:00,0\n9,DL,02/01/2021,2060,N397DA,ATL,00:00,0\n10,DL,02/01/2021,2065,N3746H,ATL,08:37,-3\n11,DL,02/01/2021,2066,N3761R,ATL,00:00,0\n12,DL,02/01/2021,2132,N3752,ATL,00:00,0\n13,DL,02/01/2021,2144,N372DA,ATL,00:00,0";

const alter_csv = (csv, label, value) => {
    const data = csv.split('\n');
    var header_row = data.slice(0,1)[0];
    var data_rows = data.slice(1);
    header_row = header_row.concat(','+ label)
    
    data_rows = data_rows.map((row)=>{return row.concat(','+ value)})
    return [header_row].concat(data_rows).join('\n')

}

export default function BtsScrape(){
    
    const make_years= () => {
        return Array.from({ length: (new Date().getFullYear()+1 - 1987) / + 1}, (_, i) => 1987 + (i ))
    }
    const years = make_years();
    var yearsObj = {};
    make_years().map((val)=>{yearsObj[val]=false});

    const make_months= () => {
        return Array.from(Array(12).keys()).map(x => ++x)
    }
    const months = make_months();
    var monthObj = {};
    make_months().map((val)=>{monthObj[val]=false});

    const make_days= () => {
        return Array.from(Array(31).keys()).map(x => ++x)
    }
    const days = make_days();
    var daysObj = {};
    make_days().map((val)=>{daysObj[val]=false});

    const make_stats= () => {
        return statistics.map((val)=>{return val.label})
    }
    const stats = make_stats();
    var statsObj = {};
    make_stats().map((val)=>{statsObj[val]=false});

    const [selectAirportOption, setSelectAirportOption] = useState();
    const [idselectAirlineOption, setidselectAirlineOption] = useState();
    const [monthsOptions, setMonthsOptions] = useState(monthObj);
    const [daysOption, setDaysOption] = useState(daysObj);
    const [yearsOption, setYearsOption] = useState(yearsObj);
    const [statsOption, setStatsOption] = useState(statsObj);
    const [csvResults, setCsvResults] = useState([]);
    const [csvTableData, setCsvTableData] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleStatsChange = (event) => {
        setStatsOption({
            ...statsOption,
            [event.target.name]: event.target.checked,
        });
    };

    const handleMonthChange = (event) => {
        setMonthsOptions({
            ...monthsOptions,
            [event.target.name]: event.target.checked,
        });
    };

    const handleDayChange = (event) => {
        setDaysOption({
          ...daysOption,
          [event.target.name]: event.target.checked,
        });
      };
    
    const handleYearChange = (event) => {
        setYearsOption({
            ...yearsOption,
            [event.target.name]: event.target.checked,
        });
    };
    
    function MonthBoxes(){
 
        return <>
        <div className="border border-black shadow bg-slate-300 rounded p-2">
            Months
            <div className="grid grid-cols-7">
                {months.map((idx)=>{
                    return <FormControlLabel
                        control={
                        <Checkbox checked={monthsOptions[idx]} onChange={handleMonthChange} name={idx} />
                        }
                        label={idx}
                    />
                })}
            </div>
        </div>
        <div className="h-2"></div>
        <div className="border border-black shadow bg-slate-300 rounded p-2">
            Days
            <div className="grid grid-cols-7">
                {days.map((idx)=>{
                    return <FormControlLabel
                        control={
                        <Checkbox checked={daysOption[idx]} onChange={handleDayChange} name={idx} />
                        }
                        label={idx}
                    />
                })}
            </div>
        </div>
        <div className="h-2"></div>
        <div className="border border-black shadow bg-slate-300 rounded p-2">
            Years
            <div className="grid grid-cols-7">
                {years.map((idx)=>{
                    return <FormControlLabel
                        control={
                        <Checkbox checked={yearsOption[idx]} onChange={handleYearChange} name={idx} />
                        }
                        label={idx}
                    />
                })}
            </div>
        </div>
        <div className="border border-black shadow bg-slate-300 rounded p-2">
            Statistics
            <div className="grid grid-cols-3">
                {statistics.map((value,idx)=>{
                    return <FormControlLabel
                        control={
                        <Checkbox checked={statsOption[value.label]} onChange={handleStatsChange} name={value.label} />
                        }
                        label={value.label}
                    />
                })}
            </div>
        </div>

        </>
    }

    function Dropdowns(){
        return <div>
             <Autocomplete
        multiple
        value={selectAirportOption}
        onChange={(event, newValue) => {setSelectAirportOption(newValue);}}
        id="airports"
        options={airports}
        getOptionLabel={(airports) => airports.label}
        // sx={{ width: 300 }}
        renderInput={(params) => <TextField
            {...params}
            variant="standard"
            label="Choose airports"
            placeholder="..."
        />}
    />
        <div className="h-2"></div>
    <Autocomplete
        multiple
        value={idselectAirlineOption}
        onChange={(event, newValue) => {setidselectAirlineOption(newValue);}}
        id="airlines"
        options={airlines}
        getOptionLabel={(airlines) => airlines.label}
        // sx={{ width: 300 }}
        renderInput={(params) => <TextField
            {...params}
            variant="standard"
            label="Choose airlines"
            placeholder="..."
        />}

    />
        </div>
    }
    const grab_true = (vals) => {
     return Object.entries(vals).filter((x)=>x[1]==true).map((x)=>{return x[0]})
    }
    const clear_state = () =>{
         setSelectAirportOption();         // = useState();
         setidselectAirlineOption();         // = useState();
        setMonthsOptions({});         // = useState(monthObj);
         setDaysOption({});         // = useState(daysObj);
         setYearsOption({});         // = useState(yearsObj);
         setStatsOption({});         // = useState(statsObj);
         setCsvResults([]);         // = useState([]);
        setCsvTableData(null);         // = useState(null);
        //  setLoading();         // = useState(false);

    }
    const execute_search = () =>{
        // selectAirportOption
        // idselectAirlineOption
        // monthsOptions
        // daysOption
        // yearsOption
        // statsOptio

        
        setLoading(true);    

        idselectAirlineOption.map((airline)=>{
            var req_vals = {
                days: grab_true(daysOption),
                months:grab_true(monthsOptions),
                years:grab_true(yearsOption),
                stats:grab_true(statsOption).map((val)=>{ 
                    return statistics.filter(stat=>stat.label==val)[0].id})
                }
            req_vals.airlines = [airline.id]
            selectAirportOption.map((airport)=>{
                req_vals.airports= [airport.id]
                
                setTimeout((idx)=>{
                    console.log(req_vals)
                    ScrapeBtsFlights(req_vals)
                        .then((r)=>{
                            console.log(r.data)
                            const data = alter_csv(r.data, 'Departure Airport', airport.id)
                            csvResults.push(data)
                            setCsvResults(csvResults)
                            make_table();
                        })
                        .catch((e)=>{
                                console.log(e);
                                alert(`${airport.id}-${airline.id} - Combo had no data`);})
                    },1000)
            })
        })
      
    }

    const make_table = ()=>{
            // csvResults [csv1,csv2] -> setCsvTableData csv
            setLoading(false);    
            if (csvResults.length ==1){
                const top = csvResults.slice(0,1)[0]
                setCsvTableData(top);
            }else if(csvResults.length > 1){
                console.log('multi')
                console.log(csvResults.slice(1))
                var top = csvResults.slice(0,1)[0]
                csvResults.slice(1).map((csv)=>{ //-> [csv_noheader,csv_noheader ]
                    console.log(csv.split('\n').slice(1))    
                    top = top.concat(csv.split('\n').slice(1).join('\n'));
                    })
                console.log('SET DATA: ',top)
                setCsvTableData(top);
            }


        };

    return <>
        <div className="h-16"></div>
        <Dropdowns/>
        <div className="h-16"></div>
        <FormGroup>
            <MonthBoxes/>
        </FormGroup>
        <Button 
            onClick={execute_search}
            >Execute</Button>
        <Button 
            onClick={clear_state}
            >Reset</Button>  
        <Button 
            onClick={make_table}
            >Refresh Table</Button>     
        {loading?<>Loading...</>:<></>}
        {csvTableData==null?
            <></>:
            <div className="h-96 w-auto">
                <CsvTable csv={csvTableData}/>
            </div>
        }
        
    </>
}