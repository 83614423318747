import React,{useState, useEffect} from 'react';
import {  Box, Stack, TextField,InputAdornment, Button,CircularProgress,Divider } from '@mui/material';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import {OptionsScanner as ScanOption}  from '../data/api';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import {Stepper, Step, StepLabel,} from '@mui/material';
import {Tab,    Slider} from '@mui/material';
import {CardContent,Card, Typography} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


function SpreadCardDisplay({spread}){
  const call_spread = spread.call_spread;
  const put_spread = spread.put_spread;
  return <>
    <div className='grid grid-rows-4 grid-cols-5 grid-flow-col gap-2 content-center' >
      <div className='row-span-2 text-2xl m-auto'>Call</div>
      <div className='row-span-2 text-2xl m-auto'>Put </div>
      <div className='col-span-1 m-auto'>Buy</div>
      <div className='col-span-1 m-auto'>Sell</div>
      <div className='col-span-1 m-auto'>Buy</div>
      <div className='col-span-1 m-auto'>Sell</div>
      <div className='col-span-1'> {call_spread.buy.description}</div>
      <div className='col-span-1'> {call_spread.sell.description}</div>
      <div className='col-span-1'> {put_spread.buy.description} </div>
      <div className='col-span-1'> {put_spread.sell.description}</div>
      <div className='col-span-1'>Premium: ${call_spread.buy.average_price}</div>
      <div className='col-span-1'>Premium: ${call_spread.sell.average_price}</div>
      <div className='col-span-1'>Premium: ${put_spread.buy.average_price}</div>
      <div className='col-span-1'>Premium: ${put_spread.sell.average_price}</div>
      <div className='col-span-1 row-span-2 m-auto'>Allocation: ${spread.cal_allocation||spread.cal_allocation}</div>
      <div className='col-span-1 row-span-2 m-auto'>Allocation: ${spread.put_allocation}</div>
    </div>
  </>
}

function SpreadCard(props){
  console.log(props)
  const spread = props.props
  return <>
  <div className='h-2'></div>
  <Card sx={{ minWidth: 275 }} variant="outlined">
  <CardContent>
    <div className='flex items-center'>
  <Typography sx={{ fontSize: 32 }} color="text.primary" gutterBottom>
    {spread.type !=='error'?<>{spread.symbol} - ${spread.last_price}    </>
        :<>In sufficient conditions for {spread.symbol}</>}
  </Typography>
  <div className='w-4'></div>
  <Typography sx={{ fontSize: 22}} color="text.secondary" gutterBottom>
    Net Premium Return %{spread.net_premium}
  </Typography>
  <div className='w-4'></div>
  <Typography sx={{ fontSize: 22}} color="text.secondary" gutterBottom>
    Upside Cap: {spread.upside_cap}
  </Typography>
  </div>
  <div className='min-w-full border-slate-400 border-t-2'></div>
  <SpreadCardDisplay spread={spread}/>
  
  </CardContent>
  </Card>
  </>
}

function ScanResults(props){
    const rows = props.rows
    return <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Symbol</TableCell>
          <TableCell align="right">Strike</TableCell>
          <TableCell align="right">Average Price</TableCell>
          <TableCell align="right">Ask</TableCell>
          <TableCell align="right">Bid</TableCell>
          <TableCell align="right">Volume</TableCell>
          <TableCell align="right">Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.symbol}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="right">{row.symbol}</TableCell>
            <TableCell align="right">{row.strike}</TableCell>
            <TableCell align="right">{row.average_price}</TableCell>
            <TableCell align="right">{row.ask}</TableCell>
            <TableCell align="right">{row.bid}</TableCell>
            <TableCell align="right">{row.volume}</TableCell>
            <TableCell align="right">{row.description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
}

export default function OptionsScanner(){
    const [search,setSearch] = useState('');
    const [response, setResponse] = useState([]);
    const [spreadResponse, setSpreadResponse] = useState([]);
    const [step, setStep]  = useState(0);
    const [loading, setLoading]  = useState(false);
    const [timeframe, setTimeFrame]  = useState(40);
    const [optionList, setOptionList]  = useState([]);
    const [allocation, setAllocation]  = useState(50);
    const [upsideCap, setUpsideCap]  = useState(.1);
    const [putGrowthYeild, setPutGrowthYeild]  = useState(.5);
    const [callGrowthYeild, setCallGrowthYeild]  = useState(.5);
    const [coveredCall, setCoveredCall]  = useState([]);
    const [protectivePut, setProtectivePut]  = useState([]);
    const [tab, setTab] = useState('');


    const add_options = () => {
      if (!optionList.includes(search)){
        if(search.includes(',')){
          setOptionList(optionList.concat(search.split(',').map((x)=>{return x.trim().toLocaleUpperCase()})))
        } else{
          setOptionList([...optionList,search.trim().toLocaleUpperCase()])
        }
      }
    }

    function OpScanner(){
      
      return
    }

    const scan_options = () => {
      setLoading(true);
      optionList.map((o)=>{
        ScanOption({symbol:o,days_ahead:timeframe, step:2})
          .then(
          (res)=>{
              res.data.symbol = o;
              response.push(res.data);
              setResponse(response);
              setLoading(false);
          })
        })
    };
    const calc_protecive_puts = () => {
      console.log('calculating protective puts')
      setLoading(true);
      optionList.map((o)=>{
        ScanOption({
          symbol:o,
          step:'covered_call',
          yield_perc:putGrowthYeild/100,
          days_ahead:timeframe
        }).then((r)=>{
          setProtectivePut([...protectivePut,r.data])
          console.log(protectivePut)
        })
      })
    };
    const calc_covered_calls = () => {
      console.log('calculating covered calls')
      setLoading(true);
      optionList.map((o)=>{
        ScanOption({
          symbol:o,
          step:'covered_call',
          yield_perc:callGrowthYeild/100,
          days_ahead:timeframe
        }).then((r)=>{
          setCoveredCall([...coveredCall,r.data])
          console.log(coveredCall)
        })
      })
    };
    const calc_spreads = () => {
      setLoading(true);
      console.log('calculating spreads')
      optionList.map((o)=>{
        ScanOption({symbol:o,days_ahead:timeframe, step:3,allocation_percent:allocation/100,upside_cap:upsideCap})
          .then(
            (res)=>{
                res.data.symbol = o;
                res.data.upside_cap = upsideCap;
                spreadResponse.push(res.data);
                console.log(spreadResponse);
                setSpreadResponse(spreadResponse);
                setLoading(false);
            })
          .catch(
            (res)=>{
              res.data.symbol = o;
              res.data.type = 'error';
              spreadResponse.push(res.data);
              setSpreadResponse(spreadResponse);
              setLoading(false);
            }
          )
        })
    };
  
    function OpScannerResult({r}){
      return <>
        <h1 className='text-2xl font-bold text-slate-600 '
            >Calls</h1><ScanResults rows={r.above}/>
        <div className='h-3 border-black'></div>
        <h1 className='text-2xl font-bold text-slate-600'
            >Puts</h1><ScanResults rows={r.below}/>
      </>
    }

    function OpScannerResultsList(){

      if (response.length!=0 && tab==''){
          setTab(response[0].symbol)
      }
      return <TabContext value={tab}>
      {
        response.length!=0?<div className='flex absolute w-screen'>
          {
            <TabList orientation="vertical"
                onChange={(e,v)=>{setTab(v)}
                }>
            {response.map((r)=>{
                return <Tab label={r.symbol + ' - ' + r.last_price} value={r.symbol}/>
              })}
          </TabList>}
          {
              response.map((r)=>{
                return <TabPanel value={r.symbol}>
                  <OpScannerResult r={r}/>
                  </TabPanel>
              })
            }
            {/* {
              response.map((r)=>{
                return <OpScannerResult response={r} />
              })
            } */}
           </div>:<></>
          } </TabContext>
        }

    function OpSpreadResultsList(){
      console.log(response)
      
      return <>
        {spreadResponse.length!=0?
              <>{
                <div className='grid space'>
                {spreadResponse.map((o)=>{
                  return  <SpreadCard props={o}></SpreadCard> 
                  })}
                  </div>
                }</>:<></>
            }
      </>
          
        }
    function OpScannerResults(){
      
      return 
    }

    function OpScannerSpreads(){
      return 
    }
    


    const steps = ['Choose Symbols','Scan Options','Calculate Spreads']


    function Buttons(){
      return <div className='flex'>
          <Button
            variant="contained"
            component="label"
            disabled={step==0}
            onClick={()=>{
              if(step > 0){
                setStep(step-1)
              }
              }}
            >
            Previous
          </Button>
          <div className='w-2'></div>
          <Button
              variant="contained"
              component="label"
              disabled={step==steps.length-1}
              onClick={()=>{
                  if(step <= steps.length-2){
                    setStep(step+1)
                  }}}
              >
              Next
          </Button>
      </div>
    }

    return <div >
                <Box sx={{height:100}}></Box> 
                
            <Stepper activeStep={step} alternativeLabel>
              {steps.map((label) => (
              <Step key={label}>
                  <StepLabel>{label}</StepLabel>
              </Step>
              ))}
            </Stepper>
              
            <Box sx={{height:10}}></Box> 
            <Buttons/>
            <Box sx={{height:50}}></Box> 
            {/* {mapping[step]} */}
    {step==0?<>
        <Stack>
            <TextField
                label="Symbol"
                defaultValue=' '
                autoFocus
                onChange={(e)=>{setSearch(e.target.value); }}
                value={search}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <ShowChartIcon />
                        </InputAdornment>
                    ),
                    }}
                    variant="standard"
                />
            <Box sx={{height:20}}></Box>
                <div className='flex space-x-2'>
                    <Button 
                        variant='outlined'
                        onClick={()=>{add_options()}}
                        >Add</Button>
                     <Button 
                        variant='outlined'
                        onClick={()=>{setOptionList([]) }}
                        >Clear</Button>
                    <Button disabled={true} variant='outlined' >Upload CSV</Button>
                    <TextField 
                        label="Timeframe(days)" 
                        variant="outlined" 
                        type="number"
                        defaultValue={40}
                        onChange={(e)=>{setTimeFrame(e.target.value)}}/>
                </div>
                <Box sx={{height:50}}></Box>
            </Stack>
            {optionList.length!=0?
              <>{
                <div className='grid space'>
                {optionList.map((o)=>{
                  return  <Paper elevation={3}>{o}</Paper> 
                  })}
                  </div>
                }</>:<></>
            }
      </>:<></>}
  {step==1?<>
                <Button 
                  variant='outlined'
                  onClick={()=>{scan_options()}}
                  disabled={optionList.length==0}
                  >Execute Scan</Button>
          <div className='w-4'></div>
          {optionList.length==0?<>Please add options to continue!</> :<></>}
          {loading ? <CircularProgress/> :<></>}
          <div className=''>
              <OpScannerResultsList/>
          </div>
      </>:<></>}
{step==2?<>
  <div className='flex flex-col'>
    <div>
      {/* <TextField 
            label="Upside Cap" 
            variant="outlined" 
            type="number"
            defaultValue={.10}
            onChange={(e)=>{setUpsideCap(e.target.value)}}/> */}
      <div className='w-2'></div>
      <Stack>
        <Stack spacing={2} direction="row">
        <div className='w-3/5'>
            <Button 
              variant='outlined'
              onClick={()=>{calc_spreads()}}
              disabled={optionList.length==0}
              >Find Suggested Spreads</Button>
        </div>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Calls {allocation}%
          </Typography>
            <Slider aria-label="Allocation_precent" 
              value={allocation} 
              size='small'
              defaultValue={50}
              onChange={(e, nv)=>{setAllocation(nv)}} /> 
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Puts {100 - allocation}%
          </Typography>
        </Stack>
      </Stack>
    </div>
      <div className='h-2'></div>
      <Divider/>
      <div className='h-2'></div>
    <Stack>
        <Stack spacing={2} direction="row">
        <div className='w-3/5'>
            <Button 
              variant='outlined'
              onClick={()=>{calc_covered_calls()}}
              disabled={optionList.length==0}
              >Find Covered Call</Button>
        </div>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Yeild {callGrowthYeild}%
          </Typography>
            <Slider aria-label="Allocation_precent" 
              value={callGrowthYeild} 
              size='small'
              defaultValue={50}
              onChange={(e, nv)=>{setCallGrowthYeild(nv)}} /> 
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Growth {100 - callGrowthYeild}%
          </Typography>
        </Stack>
      </Stack>
        <div className='h-2'></div>
        <Divider/>
        <div className='h-2'></div>
      <Stack>
        <Stack spacing={2} direction="row">
          <div className='w-3/5'>
            <Button 
              variant='outlined'
              onClick={()=>{calc_protecive_puts()}}
              disabled={optionList.length==0}
              >Find Protective Put</Button>
          </div>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Yeild {putGrowthYeild}%
          </Typography>
            <Slider aria-label="Allocation_precent" 
              value={putGrowthYeild} 
              size='small'
              defaultValue={.5}
              onChange={(e, nv)=>{setPutGrowthYeild (nv)}} /> 
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Growth {100 - putGrowthYeild}%
          </Typography>
        </Stack>
      </Stack>
  </div>
      {optionList.length==0?<>Please add options to continue!</> :<></>}
      {loading ? <CircularProgress/> :<></>}
        <div className=''>
            <OpSpreadResultsList/>
        </div>
    </>:<></>}
    {coveredCall.length!=0?
    <>{coveredCall.map((r)=>{return <div>{r.symbol}</div>})}</>
    :<></>}
    {protectivePut.length!=0?
    <>{protectivePut.map((r)=>{return <div>{r.symbol}</div>})}</>
    :<></>}       
    </div>
}