import SignInSide from './components/login'
import myContext from './components/mycontext';
import { Routes, Route } from "react-router-dom";
import { useState} from 'react';
import Dashboard from './components/dashboard'
import Checkout from './components/checkout'
import Home from './home/Home.js'
//there may be a better place for this
import axios from 'axios';
import Contact from './home/modules/views/Contact'

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL
axios.defaults.headers.common['Authorization'] = 'Bearer '+ process.env.REACT_APP_GOOGLE_CLOUD_TOKEN;


function App() {
  const [user, setUser] = useState();

  return (

    <myContext.Provider value={{user,setUser}}>
      <Routes>
      <Route path="/" element={<Home/>} />
        <Route path="/SignIn" element={<SignInSide/> } />
        {/*<Route path="/SignUp" element={<SignUp/> } />*/}
        <Route path="/Contact" element={<Contact/> } />
        <Route path="/Dashboard" element={<Dashboard/>} />
        <Route path="/Checkout" element={<Checkout/>} />
      </Routes>
      
    </myContext.Provider>
  )
}

export default App;
