import { DataGrid, GridToolbarContainer, GridToolbarExport,gridClasses } from '@mui/x-data-grid';
/*
,Carrier Code,Date (MM/DD/YYYY),Flight Number,Tail Number,Destination Airport,Actual departure time,Departure delay (Minutes)
0,DL,01/01/2021,507,N3772H,SLC,17:29,29
1,DL,01/01/2021,1297,N981AT,ATL,12:57,1
2,DL,01/01/2021,1646,N132DU,DTW,06:00,0
3,DL,01/01/2021,2060,N398DA,ATL,06:13,-2
4,DL,01/01/2021,2144,N927AT,ATL,14:33,-7
5,DL,02/01/2021,474,N3759,SLC,00:00,0
6,DL,02/01/2021,1201,N990AT,ATL,00:00,0
7,DL,02/01/2021,1255,N607AT,ATL,00:00,0
8,DL,02/01/2021,1297,N948AT,ATL,00:00,0
9,DL,02/01/2021,2060,N397DA,ATL,00:00,0
10,DL,02/01/2021,2065,N3746H,ATL,08:37,-3
11,DL,02/01/2021,2066,N3761R,ATL,00:00,0
12,DL,02/01/2021,2132,N3752,ATL,00:00,0
13,DL,02/01/2021,2144,N372DA,ATL,00:00,0
*/
function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
// input_parms -> [{label:input_label, value:input_value}]
export default function CsvTable({csv, input_parms=null}){
    //data should be a csv
    const data = csv.split('\n');
    const header_row = data.slice(0,1)[0];
    const data_rows = data.slice(1);
    
    const header_list = header_row.split(',').map((x)=>{return x.length==0?'id':x});
    var columns = header_list
                    .map((col)=>{
                        return {
                            field:col, 
                            headerName:col,
                            width:col.length * 7
                        }
                    });

    var rows = data_rows
                    .map((row)=>{
                    const vals = row.split(',') //[cat,dog,zebra]
                                    .map((val,idx)=>{
                                        return [header_list[idx],val]
                                    })
                    return Object.fromEntries(vals)
                });     
    
    if(input_parms!=null){
    // input_parms -> [{label:input_label, value:input_value}]
        input_parms.map((x)=>{
            columns.push({
                field:x.label, 
                headerName:x.label,
                width:x.label.length * 7
            })
            rows.map((row)=>{
                row[x.label] = x.value
            })

    })

    };

    return  <DataGrid
        components={{ Toolbar: CustomToolbar }}
        rows={rows}
        columns={columns}
        checkboxSelection
        autoHeight
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
    />
}