
import MyAvatar from './customComponents/avatar';
import {GetUserComponentData} from './data/api'
import AppBarCustom from './appbar';
import StripeObject from './customComponents/stripe_object';

export default function Checkout(){
    var t =  {id: "prod_MjmdBqjrwQxa98",
          object: "product",
          active: true,
          created: 1667542048,
          default_price: "price_1M0J3tB8L3kdvj5uHzZqRqkw",
          description: "Find out what's being posted where",
          images: [
            "https://files.stripe.com/links/MDB8YWNjdF8xS1JWTUlCOEwza2R2ajV1fGZsX3Rlc3RfT0l5TWNONW1zNzJHNnVzQndyM3lNdFl200FZmwoekF"
          ],
          livemode: false,
          metadata: {},
          name: "Facebook Marketplace Scraper",
          package_dimensions: null,
          shippable: null,
          statement_descriptor: null,
          tax_code: "txcd_10000000",
          unit_label: null,
          updated: 1667542049,
          url: null}
    return <div className=' grid grid-cols-8'>
                <div className='col-span-2'></div>
                <div className='col-span-1'>
                    <StripeObject StripeObject = {t}/>
                </div>
                
        </div>

}