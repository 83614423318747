import {db} from '../myFirebase';
import {doc, getDoc, collection, setDoc } from 'firebase/firestore'

const accounts= collection(db,'accounts')
const apps= collection(db,'apps')

async function getApps(user){
    const user_settings = doc(accounts,'/'+user.uid);
    const apps = await getDoc(user_settings);

    if (apps.exists()){
        const data = apps.data();
        return data.apps
    } else{
        //run app creatoin here
        setDefaultApps(user);
        return null
    };
}

async function setDefaultApps(user){
    //this will need to be verified on the backed that the value are the ones selected
    const data = {
                apps:
                    { 
                        0:{app_alias:"Demo Indeed Scraper", app_id:1},
                        1:{app_alias:"Demo Google Scraper", app_id:0}
                    }
                };
    const _ = await setDoc(doc(accounts, '/'+ user.uid),data);

    
}

export {getApps, setDefaultApps};