import React,{useContext, useState, useEffect} from 'react';
import { IconButton, Button, Box, Popover, Typography, ButtonGroup,Skeleton} from '@mui/material';
import {getSavedData, getSavedDoc} from '../data/data_settings'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import myContext from '../mycontext';

const style = {
    button:{
        width:'10px'
    }
}

export default function SavedDataDropDown({setData, apps, button}){
    /*
    Title drop down arrow that contains users saved files, 
    should load on click and set data in the setData function 
    */
    const {user, _} = useContext(myContext);
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState();
    const open = Boolean(anchorEl);
   
    const create_popover_element=(title, id)=>{
        //each button handles its own shit
        const handleClick = (e)=>{
            const file_id = e.target.id
    
            getSavedDoc(user,file_id).then(
                (r)=>{
                    setAnchorEl(null)
                    setData(r.data)
                }).catch((r)=>{window.alert(r)})
        }
        return (<Button key={id} id={id} onClick={handleClick}>{title}</Button>)
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
        getSavedData(user).then((r)=>{
           // console.log(r)
            const options = r.data.map(
                (val)=>{
                    return create_popover_element(val.title, val.id)
                 })
            setPopoverContent(
                <ButtonGroup orientation='vertical'>{options}</ButtonGroup>
                )
        })
      };
      const Skeletons = () =>{
          return(<>
            <Skeleton variant="rectangular" width={120} height={30} />
            <Skeleton variant="rectangular" width={120} height={30} />
            <Skeleton variant="rectangular" width={120} height={30} />
            </>)
      }
      const handleClose = () => {
        setAnchorEl(null);
      };


    
    const onChange = (e) =>{
        setData(e.target.value)
    }

    return (
        <>
        <ButtonGroup>
            {button}
            <Button 
                onClick={handleClick}
                color="primary" 
                variant="contained" 
                sx={style.button}
                startIcon={ <ArrowDropDownIcon/>}>
            
            </Button>
        </ButtonGroup>
        
        <Popover
            id='data-dropdown'
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            >
                {popoverContent==null?<Skeletons/>:popoverContent}
            </Popover>
        </>)
}